import ReactPlayer from "react-player";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

const VideoModal = ({ close, setVideoModalShown }) => {
  const [mute, setMute] = useState(false);

  useEffect(() => {
    setMute(true);
  }, []);
  return (
    <div className='w-screen px-8  lg:px-0 flex justify-center'>
      <div
        style={{
          background:
            " radial-gradient(483.95% 341.27% at -174.95% 37.09%, #FF790C 0%, #FFB73A 56.73%, #FFBA3C 59.23%, #FFBB41 67.72%, #FFC04E 77.27%, #FFC765 87.34%, #FFD084 97.7%, #FFD38C 100%)",
          fontFamily: "Arial",
        }}
        className='py-[1.5rem] px-[1.5rem]  lg:px-[2.5rem] flex flex-col items-center rounded-[0.625rem] sm:rounded-[1.25rem]  h-auto w-full sm:w-auto'>
        <button
          onClick={() => setVideoModalShown(false)}
          type='button'
          className='self-end outline-none'>
          <Icon icon='mdi:close' className='text-[1.5rem]' />
        </button>
        <div className='rounded-[0.6rem] overflow-hidden w-full h-[200px] sm:h-[300px] sm:w-[400px] md:w-[600px] md:h-[400px]'>
          <ReactPlayer
            muted
            className='rounded-[0.5rem]'
            url={
              "https://res.cloudinary.com/deh78ntmd/video/upload/v1713158131/videoplayback_1_r07ant.mp4"
            }
            controls
            playing
            width='100%'
            height='100%'
          />
          {/* <iframe
            width='100%'
            height='100%'
            src='https://www.youtube.com/embed/mkbA9_vyrQ0?mute=0&autoplay=1'
            title='April 11, 2024'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen></iframe> */}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
