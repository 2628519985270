import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import logo from "../assets/RetireHow 1.png";
import waterfalls from "../assets/waterfall-sun.svg";
import largeWaterfalls from "../assets/large-waterfalls.svg";
import chakra from "../assets/chakra-birds.svg";
import largeChakra from "../assets/largeChakra.svg";
import tajmahal from "../assets/tajmahal.svg";
import mobileGrass from "../assets/mobileGrass.svg";
import grass from "../assets/Grass.svg";
import family from "../assets/family.svg";
import car from "../assets/car.svg";
import bush1 from "../assets/bush1.svg";
import bush2 from "../assets/bush2.svg";
import topGrass from "../assets/topGrass.svg";
import WaitlistModal from "../helpers/WaitListModal";
import Popup from "reactjs-popup";
import leftCloud from "../assets/leftCloud.svg";
import rightCloud from "../assets/rightCloud.svg";
import "./style.css";
import VideoModal from "../helpers/VideoModal";

const Home = () => {
  const [isContactShown, setContactsShown] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [videoModalShown, setVideoModalShown] = useState(false);
  const videoPopupRef = useRef(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const popupRef = useRef(null);

  function isValidEmail() {
    return emailRegex.test(email);
  }

  const saveEmail = async () => {
    try {
      const url =
        "https://skysheetapi.onrender.com/api/V1/adddata/1LBdCNlhb0UrEBCWx8RAWHTnb2BuQk0nUD2LT6AXX_L4?sheetIndex=1";

      const options = {
        method: "POST",
        body: JSON.stringify({ Email: email }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      if (response.ok) {
        popupRef.current.open();
        setValidEmail(false);
        setEmail("");
      } else {
        alert(responseObj.message);
      }

      console.log(response, "aioush");
      console.log(responseObj, "responseoBj");
    } catch (e) {
      console.log(e);
    }
  };

  const addToWaitList = async () => {
    console.log(isValidEmail(), "email validation");

    if (isValidEmail() === true) {
      saveEmail();
    } else {
      console.log("fun called");

      if (email === "") {
        alert("Enter The Email Id");
      } else {
        alert("Invalid Email");
      }

      //setValidEmail(true);
    }
  };

  useEffect(() => {
    setVideoModalShown(true);
  }, []);

  console.log(email, "email received");

  return (
    <div className='main'>
    
      <div className='px-[1rem] lg:px-[2rem]  flex flex-col gap-[2.5rem] lg:gap-0  '>
        <img
          src={logo}
          alt='logo'
          className='h-[6rem] w-auto pt-[1.5rem] lg:pt-[3.5rem] lg:h-[8.68rem] xl:h-[9rem] lg:pl-[4rem] lg:w-auto lg:self-start self-center'
        />

        <div className='flex flex-col items-center gap-[1rem] lg:absolute lg:top-[6.5rem] w-full '>
          <div className='flex flex-col items-center gap-[0.5rem]  lg:max-w-[80%] '>
            <h1 className='text-[1.5rem] lg:text-[2.5rem] xl:text-[4rem] text-center font-extrabold font-manrope  '>
              LAUNCHING SOON
            </h1>
            <p
              style={{ fontFamily: "Arial" }}
              className='font-raleway text-center text-[0.875rem] font-normal text-[#000] tracking-[0.14px]   lg:text-[1.5rem]'>
              Discover the freedom to retire in style and travel on your terms
              with Retirehow.com and TravelGlobal.ca. Join the expanding list of
              individuals, business owners and families eager to dive into the
              realm of retirement planning and long term stays abroad.
            </p>
          </div>

          <div
            style={{ fontFamily: "Arial" }}
            className={`bg-white rounded-[100px]  py-[0.4rem] sm:py-[0.75rem] xl:py-[0.5rem] pl-[1.5rem] pr-[1rem] z-40 flex w-full max-w-[573px]`}>
            <input
              placeholder='Enter your mail'
              type='email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className='outline-none text-[#565656] placeholder:text-[#565656] text-[0.50rem] xsm:text-[0.65rem] sm:text-[1rem] placeholder:font-normal font-bold flex-1 sm:pr-[1rem] sm:flex-1 '
            />

            <button
              type='button'
              onClick={addToWaitList}
              className='bg-[#FF7E10]     py-[0.75rem] px-[1rem] rounded-[6.25rem] shrink-0 text-[0.45rem] sm:text-[0.875rem]   font-bold text-white'>
              Join the Waitlist
            </button>
            <Popup
              ref={popupRef}
              modal
              position={"center center"}
              open={validEmail}>
              {(close) => <WaitlistModal close={close} />}
            </Popup>
          </div>
          <div className='bg-white text-[#FF7E10] py-[0.37rem] px-[0.74rem] rounded-[2.3rem] flex flex-row items-center z-30'>
            <div className='flex flex-row items-center gap-[0.4rem]'>
              <Icon
                icon='ic:baseline-phone'
                className='text-[0.875rem] sm:text-[1rem] lg:text-[1.5rem]'
              />
              <p className='font-manrope text-[0.65rem] xsm:text-[0.875rem] md:text-[1rem] lg:text-[1.5rem] font-bold'>
                +1(289) 815-3631
              </p>
            </div>

            <hr className='w-[0.74px] bg-[#CDCDCD] h-[1.5rem] xsm:h-[27px]  mx-[0.375rem] ' />

            <div className='flex flex-row items-center gap-[0.4rem]'>
              <Icon
                icon='ic:baseline-email'
                className='text-[0.875rem] xsm:text-[1rem] lg:text-[1.5rem]'
              />
              <p className='font-manrope text-[0.65rem] xsm:text-[0.875rem] md:text-[1rem] lg:text-[1.5rem] font-bold'>
                Rao.movva@retirehow.com
              </p>
            </div>
          </div>

          <div
            className={`flex  items-center h-auto z-50 absolute ${
              isContactShown ? "left-[30px]" : "left-[-52px]"
            }  top-[55%] xsm:top-[45%] transition-all duration-4000 lg:hidden`}>
            <div className='p-[0.75rem] xl:p-[0.5rem] bg-white flex flex-col gap-[1rem] rounded-[3.125rem] '>
              <a
                href={
                  "https://www.facebook.com/share/Wa4ZNZ8DjnuMudjC/?mibextid=qi2Omg"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] hover:shadow-xl rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:facebook-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={
                  "https://www.linkedin.com/in/rao-movva-pfp%C2%AE-cim%C2%AE-ciwm-fcsi%C2%AE-1826263/"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:linkedin-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={
                  "https://www.instagram.com/founder_ceo_travelglobal.ca?igsh=bm10cXBka2g5Y3dh"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:instagram-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={
                  "https://x.com/FallowRaoMovva?t=TtOXJBp-ztKfOUZNiDYFXQ&s=09"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:twitter-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={"https://www.youtube.com/@movvarao1060"}
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:youtube-fill'
                  className='text-white text-[1rem]'
                />
              </a>
            </div>
            <button
              type='button'
              onClick={() => setContactsShown(!isContactShown)}
              className='w-[2.5rem] h-[2.5rem] bg-white flex justify-center items-center  rounded-r-[3.125rem]  xl:hidden'>
              <Icon
                icon={
                  !isContactShown
                    ? "fluent:chevron-right-12-filled"
                    : "fluent:chevron-left-12-filled"
                }
                className='text-[1rem] sm:text-[1.5rem] text-[#000000]'
              />
            </button>
          </div>
        </div>
        <div className='p-[1rem] z-50 bg-white lg:flex flex-col items-center gap-[1rem] rounded-[3.125rem] left-[30px] absolute top-[25%] hidden'>
          <a
            href={
              "https://www.facebook.com/share/Wa4ZNZ8DjnuMudjC/?mibextid=qi2Omg"
            }
            target='_blank'
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] flex justify-center items-center bg-[#FF7E10] rounded-full cursor-pointer transition duration-300 hover:scale-110'>
            <Icon icon='ri:facebook-fill' className='text-white text-[1rem]' />
          </a>
          <a
            href={
              "https://www.linkedin.com/in/rao-movva-pfp%C2%AE-cim%C2%AE-ciwm-fcsi%C2%AE-1826263/"
            }
            target='_blank'
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
            <Icon icon='ri:linkedin-fill' className='text-white text-[1rem]' />
          </a>

          <a
            href={
              "https://www.instagram.com/founder_ceo_travelglobal.ca?igsh=bm10cXBka2g5Y3dh"
            }
            target='_blank'
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
            <Icon icon='ri:instagram-fill' className='text-white text-[1rem]' />
          </a>
          <a
            href={"https://x.com/FallowRaoMovva?t=TtOXJBp-ztKfOUZNiDYFXQ&s=09"}
            target='_blank'
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] flex justify-center items-center bg-[#FF7E10] rounded-full cursor-pointer transition duration-300 hover:scale-110'>
            <Icon icon='ri:twitter-fill' className='text-white text-[1rem]' />
          </a>
          <a
            href={"https://www.youtube.com/@movvarao1060"}
            target='_blank'
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] flex justify-center items-center bg-[#FF7E10] rounded-full cursor-pointer transition duration-300 hover:scale-110'>
            <Icon icon='ri:youtube-fill' className='text-white text-[1rem]' />
          </a>
        </div>
      </div>

      <div className='absolute bottom-[120px] lg:bottom-[140px] 3xl:bottom-[180px]  5xl:bottom-[200px] 6xl:bottom-[250px] w-full'>
        <div className='relative w-full '>
          <div className='w-full  flex flex-row justify-between absolute z-10'>
            <img
              src={largeWaterfalls}
              alt=''
              className={`absolute hidden xl:block xl:h-[28rem] lg:top-[-24rem] 3xl:h-[35rem] 5xl:h-[40rem] 5xl:top-[-30rem]   overflow-hidden`}
            />{" "}
            <img
              src={largeChakra}
              alt=''
              className='absolute xl:h-[27rem] xl:top-[-24.2rem] 2xl:h-[27rem] 3xl:h-[30rem] 3xl:top-[-26rem] 5xl:h-[34rem] 5xl:top-[-29rem] hidden xl:block right-0 '
            />
            <img
              src={waterfalls}
              alt=''
              className={`absolute h-[9.5rem] w-auto  z-0 top-[-8.4rem]  sm:h-[12.5rem] sm:top-[-11rem] md:h-[17.5rem] md:top-[-15.78rem] mmd:h-[19.5rem] mmd:top-[-16.78rem]  xl:hidden overflow-hidden`}
            />
            <img
              src={chakra}
              alt=''
              className='absolute h-[9rem] w-auto top-[-8.1rem] sm:h-[12rem] sm:top-[-10.5rem] md:h-[15rem] md:top-[-13.5rem] mmd:h-[20rem] mmd:top-[-18rem] lg:top-[-17.7rem]  xl:hidden  right-0 z-0'
            />
          </div>

          <div
            style={{
              //backgroundColor: "red",
              backgroundImage: `url(${grass})`,
              backgroundSize: "cover",
            }}
            className='absolute h-[120px] lg:h-[140px] 3xl:h-[180px] 5xl:h-[200px] 6xl:h-[250px]  w-full overflow-visible z-20
    '>
            <div className='relative w-full h-full   '>
              <div className='flex flex-row justify-between w-full absolute top-[-0.45rem] h-[1.5rem] sm:h-[2rem] md:h-[2.5rem] lg:h-[3rem] xl:h-[4.5rem]  lg:top-[-0.5rem] xl:top-[-1rem] 4xl:top-[0.8rem] px-[1rem] lg:px-[6rem]'>
                <img src={bush1} alt='' className='h-full w-auto' />
                <img src={bush1} alt='' className='h-full w-auto' />
              </div>

              <div className='flex flex-row gap-[1rem] absolute bottom-[40%] left-[10%] lg:bottom-[20%] 5xl:bottom-[30%] lg:left-[20%] '>
                <img
                  src={family}
                  alt=''
                  className=' h-[7.75rem] xsm:h-[10rem] sm:h-[11rem]  lg:h-[14rem] xl:h-[20rem]  w-auto'
                />
                <img
                  src={car}
                  alt=''
                  className='h-[2.5rem] xsm:h-[6rem] sm:h-[7rem]   w-auto self-end'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={videoModalShown}
        ref={videoPopupRef}
        modal
        position={"center center"}>
        {(close) => (
          <VideoModal close={close} setVideoModalShown={setVideoModalShown} />
        )}
      </Popup>
    </div>
  );
};

export default Home;
