const WaitlistModal = ({ close }) => {
  return (
    <div className='w-screen px-10  lg:px-0 flex justify-center'>
      <div
        style={{
          background:
            " radial-gradient(483.95% 341.27% at -174.95% 37.09%, #FF790C 0%, #FFB73A 56.73%, #FFBA3C 59.23%, #FFBB41 67.72%, #FFC04E 77.27%, #FFC765 87.34%, #FFD084 97.7%, #FFD38C 100%)",
          fontFamily: "Arial",
        }}
        className='py-[2.5rem] px-[1.5rem] lg:py-[5rem] lg:px-[2.5rem] flex flex-col items-center rounded-[0.625rem] sm:rounded-[1.25rem] gap-[1rem] lg:gap-[1.5rem]'>
        <h1 className=' text-[1rem] sm:text-[1.5rem] lg:text-[2.5rem] text-[#000] font-bold text-center'>
          You are on the waitlist
        </h1>
        <p className='text-[0.875rem] sm:text-[1.4rem] lg:text-[1.65rem] font-normal text-center'>
          Thanks for joining waitlist we would notify you for beta release stay
          tuned :)
        </p>
        <button
          type='button'
          onClick={() => close()}
          className='py-[0.5rem] px-[1.5rem] sm:py-[0.65rem] sm:px-[2.5rem] rounded-[6.25rem] bg-[#FF7E10] text-[0.875rem] sm:text-[1.1rem] text-white font-bold '>
          Okay
        </button>
      </div>
    </div>
  );
};

export default WaitlistModal;
